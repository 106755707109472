import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { Layout, Pagination } from "../components/common";
import { MetaData } from "../components/common/meta";
import { PostItem } from "../components";

const Blog = ({ data, location, pageContext }) => {
    const posts = data.allGhostPost.edges;

    return (
        <>
            <MetaData location={location} title={"Blog"} />
            <Layout>
                <div className="container">
                    <h1>Blog</h1>
                    <section className="PostList">
                        {posts.map(({ node }) => (
                            <PostItem key={node.id} post={node} />
                        ))}
                    </section>
                    <Pagination pageContext={pageContext} />
                </div>
            </Layout>
        </>
    );
};

Blog.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
};

export default Blog;

export const blogQuery = graphql`
    query GhostBlogQuery($limit: Int!, $skip: Int!) {
        allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
